.App {
  text-align: center;
  background-color: #282c34;
}
.body, html {
  background-color: #282c34;
  padding: 10px;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1, h2 {
  color: white;
}
.App-podsale {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

